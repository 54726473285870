import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../runtime-context';
import { getTagLayout } from '../../../selectors/app-settings-selectors.js';
import { isTagLayoutButton } from '@wix/communities-blog-client-common';
import withTranslate from '../../../hoc/with-translate';
import classNames from 'classnames';
import styles from './tags-empty-state.scss';

export const TagsEmptyState = ({ t, tagLayout }) => {
  const layoutClass = isTagLayoutButton(tagLayout) ? styles.buttonLayout : styles.textLayout;

  return <div className={classNames(styles.container, layoutClass)}>{t('empty-state.no-tags')}</div>;
};

TagsEmptyState.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = state => ({
  tagLayout: getTagLayout(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(TagsEmptyState);
