import { flowRight, get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../common/components/runtime-context';

import { EXPERIMENT_RCE_VIEWER_ANCHORS, EXPERIMENT_RCE_DISABLE_THEME } from '@wix/communities-blog-experiments';
import {
  isExperimentEnabled,
  getSectionUrl,
  getIsPostPageBundle,
  getVideoUrl,
  getImageUrl,
} from '@wix/communities-blog-client-common';

import * as richContent from '../services/rich-content';
import {
  getPostPageFontSizeMobile,
  getPostHeaderTwoFontSizeMobile,
  getPostHeaderThreeFontSizeMobile,
  getPostQuotesFontSizeMobile,
} from '../../common/selectors/app-settings-selectors';
import { getAppSettings } from '../../common/selectors/app-settings-base-selectors';
import { isSeo, isEditor, isPreview, getUrl, isSSR } from '../../common/store/basic-params/basic-params-selectors';
import withHocName from '../../common/hoc/with-hoc-name';
import withDeviceType from '../../common/hoc/with-device-type';
import withRichContentFontClassName from '../../common/hoc/with-rich-content-font-class-name';
import withCardBackgroundColor from '../../common/hoc/with-card-background-color';
import Fullscreen from '../components/rich-content-fullscreen';
import { getIframeSandboxDomain } from '../../common/selectors/rce-props-selectors';
import withMediaHosts from '../../common/hoc/with-media-hosts';

export default function withRCVProps(WrappedComponent) {
  const Wrapper = props => {
    const { RCVProps, RCVActions, BI, imageHost, videoHost, ...rest } = props;
    const mappedProps = {
      ...RCVProps,
      ...rest,
      actions: RCVActions,
      bi: BI,
      getVideoUrl: src => getVideoUrl({ pathname: src, videoHost }),
      getImageUrl: image => getImageUrl({ image, imageHost }),
      emotion: { css: () => '' },
      richContent,
    };

    return <WrappedComponent {...mappedProps} />;
  };

  Wrapper.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    RCVProps: PropTypes.object.isRequired,
    RCVActions: PropTypes.object.isRequired,
    imageHost: PropTypes.string.isRequired,
    videoHost: PropTypes.string.isRequired,
    richContentFontClassName: PropTypes.string.isRequired,
  };

  return flowRight(
    connect(mapRuntimeToProps),
    withDeviceType,
    withRichContentFontClassName,
    withCardBackgroundColor,
    withMediaHosts,
    withHocName('WithRCVProps'),
  )(Wrapper);
}

const mapRuntimeToProps = (state, ownProps, actions) => ({
  RCVProps: {
    viewerKey: computeViewerKey(state),
    appSettings: getAppSettings(state),
    pageUrl: getUrl(state),
    postId: get(ownProps, 'post._id'),
    isOneApp: false,
    enableLinks: true,
    isPostPageEnabled: true,
    isSeo: isSeo(state),
    isSSR: isSSR(state),
    isOOI: true,
    normalize: { disableInlineImages: true },
    isInEditor: () => isEditor(state),
    isInPreview: () => isPreview(state),
    hashtagNavigate: actions.navigateWithinBlog,
    isPostPageBundle: () => getIsPostPageBundle(state),
    addAnchors: isExperimentEnabled(state, EXPERIMENT_RCE_VIEWER_ANCHORS),
    disableTheme: isExperimentEnabled(state, EXPERIMENT_RCE_DISABLE_THEME),
    fullscreenComponent: Fullscreen,
    sectionUrl: getSectionUrl(state),
    iframeSandboxDomain: getIframeSandboxDomain(state),
  },
  RCVActions: {
    requestFileDownloadUrl: actions.requestFileDownloadUrlPromisified,
    showMessage: actions.showMessage,
  },
  BI: {
    fileDownloaded: actions.fileDownloaded,
  },
});

const computeViewerKey = state =>
  '' +
  getPostPageFontSizeMobile(state) +
  getPostHeaderTwoFontSizeMobile(state) +
  getPostHeaderThreeFontSizeMobile(state) +
  getPostQuotesFontSizeMobile(state);
