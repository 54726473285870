import { get, chain, truncate, pickBy, isEqual } from 'lodash';
import { convertToRaw } from 'wix-rich-content-editor/dist/lib/editorStateConversion.cjs';

import {
  dateLib,
  getPostImageCover,
  getPostVideoEmbedCover,
  getPostSeoDescription,
} from '@wix/communities-blog-client-common';
import { createSlug } from '../../common/services/slug';
import getOuterUrl from '../../common/services/get-outer-url';
import { getFieldValues, getFieldInitialValues } from '../../common/services/form';

export const getPostSettingsPageTitleKey = t => t('post-settings-page-metadata.title');

export const getPostSeoTitle = ({ seoTitle, title }) => seoTitle || title;

export const getPostValues = (formFields, imageHost, videoHost) => {
  const { title, content } = getFieldValues(formFields);
  const contentRaw = convertToRaw(content);
  return {
    title,
    description: getPostSeoDescription({ content: contentRaw }),
    firstImage:
      getPostImageCover({ content: contentRaw }) ||
      get(getPostVideoEmbedCover({ content: contentRaw }, imageHost, videoHost), 'thumbnail_url'),
  };
};

export const getPostSettingsValues = (formFields, owner = {}) => {
  const {
    seoTitle,
    title,
    seoDescription,
    excerpt,
    content,
    seoSlug,
    firstPublishedDate,
    categoryIds,
    coverImage,
    isFeatured,
    ownerId,
    ownerSiteMemberId,
    canonicalUrl,
  } = getFieldValues(formFields);

  return {
    seoTitle: getPostSeoTitle({ seoTitle, title }),
    seoDescription: getPostSeoDescription({ seoDescription, content: convertToRaw(content) }),
    seoSlug:
      seoSlug ||
      createSlug(title, {
        allowYearMonthDayPrefix: true,
        allowJapanesePunctuation: true,
      }),
    firstPublishedDate,
    categoryIds: categoryIds || [],
    coverImage: coverImage || {},
    isFeatured: Boolean(isFeatured),
    ownerId: ownerId || owner._id || '',
    ownerSiteMemberId: ownerSiteMemberId || owner.siteMemberId || '',
    excerpt: excerpt || '',
    canonicalUrl,
  };
};

const MAX_URL_LENGTH = 20;

const getPathFromUrl = sectionUrl =>
  chain(getOuterUrl('/', sectionUrl))
    .trim('/')
    .split('/')
    .last()
    .value();

export const getPostUrlRoot = (isSite, sectionUrl) => {
  const path = isSite ? getPathFromUrl(sectionUrl) : '';
  const truncated = truncate(path, { length: MAX_URL_LENGTH });
  return truncated ? `/${truncated}/` : '/';
};

export const getGooglePreviewUrl = ({ slug, baseUrl, isSite, sectionUrl }) =>
  isSite ? getOuterUrl(`/${slug}`, sectionUrl) : `${baseUrl}/${slug}`;

const shouldUpdateValue = (previous, current) => !isEqual(previous, current);

const useCurrentTimeForChangedDate = (initialValue, value) => {
  if (!value) {
    return '';
  }
  if (dateLib(initialValue).isSame(value, 'day')) {
    return initialValue;
  }

  const date = dateLib(value);
  const result = dateLib()
    .year(date.year())
    .month(date.month())
    .date(date.date());

  return result.format();
};

export const getChangedPostSettingsValues = fields => {
  const initialValues = getFieldInitialValues(fields);
  const values = getFieldValues(fields);
  if (!isEqual(initialValues.firstPublishedDate, values.firstPublishedDate)) {
    values.firstPublishedDate = useCurrentTimeForChangedDate(
      initialValues.firstPublishedDate,
      values.firstPublishedDate,
    );
  }
  return pickBy(values, (value, key) => shouldUpdateValue(initialValues[key], value));
};
