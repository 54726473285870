import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../runtime-context';
import { isEditor } from '../../store/basic-params/basic-params-selectors';
import ScrollListener from '../scroll-listener';

class OnScreen extends React.Component {
  state = { isOnScreen: false };
  placeholderRef = null;

  isOnScreen() {
    const rect = this.placeholderRef && this.placeholderRef.getBoundingClientRect();
    return rect && rect.top < window.innerHeight + this.props.threshold;
  }

  onScroll = () => {
    if (this.isOnScreen()) {
      this.setState({ isOnScreen: true });
    }
  };

  componentDidMount() {
    this.onScroll();
  }

  render() {
    return this.state.isOnScreen || this.props.isEditor ? (
      this.props.children()
    ) : (
      <div ref={element => (this.placeholderRef = element)}>
        <ScrollListener onScroll={this.onScroll} />
      </div>
    );
  }
}

OnScreen.propTypes = {
  threshold: PropTypes.number.isRequired,
  isEditor: PropTypes.bool.isRequired,
};

OnScreen.defaultProps = {
  threshold: 200,
};

const mapRuntimeToProps = state => ({
  isEditor: isEditor(state),
});

export default connect(mapRuntimeToProps)(OnScreen);
