import { get, flowRight, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';

import { isPublished } from '@wix/communities-blog-universal/dist/src/post-utils';
import { POST_TITLE_MAXLENGTH } from '@wix/communities-blog-universal/dist/src/constants/post';
import { hasActiveUploads } from '@wix/communities-blog-client-common';
import { InputText } from '@wix/communities-blog-client-common/dist/esm/components';
import { getPostSettingsValues, getPostValues } from '../../../post-settings/services/post-settings';
import { isTextDirectionRTL } from '../../../rich-content/services/rich-content';
import UserAvatarImage from '../../../common/components/user-avatar-image/user-avatar-image';
import PostMetadataLayout from '../post-metadata-layout';
import UnpublishedChangesBadge from '../unpublished-changes-badge';
import RichContentEditor from '../../../rich-content/components/rich-content-editor';
import CharacterCount from '../character-count';
import TimeToRead from '../../../common/components/time-to-read';
import withTranslate from '../../../common/hoc/with-translate';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withAuth from '../../../common/hoc/with-auth';
import withDeviceType from '../../../common/hoc/with-device-type';
import withPostPublish from '../../hoc/with-post-publish';
import {
  getPostEditorPost,
  getHasUnpublishedChanges,
  getPostEditorPostOwner,
} from '../../../common/store/post-editor/post-editor-selectors';
import { getPostTitleFontSizeMobile } from '../../../common/selectors/app-settings-selectors';
import getContentStateCharacterCount from '../../../common/services/get-content-state-character-count';
import { handleEnterKeyUp } from '../../../common/services/accessibility';
import { TAB_GENERAL } from '../../../post-settings/constants/tabs';
import PostSettingsToggle from '../../../post-settings/components/post-settings-toggle';
import PostFormActionBar from './post-form-action-bar';
import styles from './post-form.scss';
import withMediaHosts from '../../../common/hoc/with-media-hosts';

const AUTOSAVE_WAIT = 5000;

export class PostForm extends Component {
  constructor(props) {
    super(props);
    const { content, ...values } = props.fastForm.values;

    this.state = {
      initialValues: values,
      initialContent: content,
    };

    this.performAutoSave = debounce(this.autoSave, AUTOSAVE_WAIT);
  }

  componentDidUpdate() {
    this.performAutoSave();
  }

  autoSave() {
    if (this.isCtaDisabled() || typeof window === 'undefined' || get(document, 'activeElement.nodeName') === 'INPUT') {
      return;
    }
    this.props.savePostDraft();
  }

  handleDrop = event => event.preventDefault();

  isCtaDisabled() {
    const {
      fastForm: { isSubmitting, isValid, values },
    } = this.props;
    return isSubmitting || !isValid || hasActiveUploads(values.content);
  }

  openPostSettingsModal = () => {
    const {
      fastForm: { fields },
      openPostSettings,
      owner,
      isPublished,
      postId,
      imageHost,
      videoHost,
    } = this.props;

    openPostSettings({
      activeTab: TAB_GENERAL,
      formInitialValues: getPostSettingsValues(fields, owner),
      postValues: getPostValues(fields, imageHost, videoHost),
      focusOnCloseId: PostForm.FOCUSABLE_ID_SETTINGS_TOGGLE,
      isPublished,
      postId,
      canPublish: !this.isCtaDisabled(),
    });
  };

  renderPostSettingsToggle = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      return null;
    }

    return (
      <PostSettingsToggle
        className={classNames(styles.settingsToggle, 'blog-link-hover-color', 'blog-link-hover-fill', 'blog-icon-fill')}
        onClick={this.openPostSettingsModal}
        onKeyUp={handleEnterKeyUp(this.openPostSettingsModal)}
        tabIndex="0"
        data-hook="post-form__post-settings-toggle"
        id={PostForm.FOCUSABLE_ID_SETTINGS_TOGGLE}
      />
    );
  };

  renderMetadata() {
    const {
      owner,
      hasUnpublishedChanges,
      timeToRead,
      fastForm: { values },
    } = this.props;

    const characterCount = getContentStateCharacterCount(values.content);

    return (
      <div className={styles.header}>
        <div className={styles.desktopOnly}>
          <div className={styles.metadata}>
            <UserAvatarImage user={owner} className={styles.userImage} />
            <PostMetadataLayout itemClassName={styles.metadataItem}>
              <span data-hook="post-form__owner-name">{owner.name}</span>
              {// prettier-ignore
              characterCount ? [
                  <TimeToRead key="read-time" minutes={timeToRead}/>,
                  <CharacterCount key="character-count" count={characterCount}/>,
                ] : null}
            </PostMetadataLayout>
          </div>
        </div>
        <div className={styles.headerWriterContent}>
          {hasUnpublishedChanges ? (
            <div className={styles.unpublishedBadge}>
              <UnpublishedChangesBadge borderClassName={styles.unpublishedBadgeBorders} />
            </div>
          ) : null}
          {this.renderPostSettingsToggle()}
        </div>
      </div>
    );
  }

  onContentChange = value => {
    if (!this.state.initialContent) {
      this.setState({ initialContent: value });
    }
    this.props.fastForm.changeValue('content')(value);
  };

  onClickCancel = () => {
    this.props.fastForm.resetForm();
    this.props.goToFeed();
  };

  renderEditor() {
    const { t, fastForm, postId } = this.props;

    return (
      <div className={classNames(styles.richContentEditor)}>
        <RichContentEditor
          placeholder={t('text-editor.placeholder')}
          initialState={fastForm.values.content || undefined}
          onChange={this.onContentChange}
          externalModalsEnabled
          postId={postId}
        />
      </div>
    );
  }

  render() {
    const {
      fastForm,
      limit,
      postId,
      t,
      contentFontClassName,
      titleFontClassName,
      isMobile,
      postTitleFontSizeMobile,
    } = this.props;

    const titleValue = fastForm.values.title || '';
    const titleDirection = isTextDirectionRTL(titleValue) ? 'rtl' : undefined;
    const onTitleChange = value => fastForm.changeValue('title')(value);
    const containerClassName = classNames(styles.container, 'blog-text-color', contentFontClassName);
    const contentClassName = classNames(styles.content);
    const titleInputClassName = classNames(styles.titleInput, 'blog-post-title-color');
    const titleInputWrapperClassName = classNames(styles.titleInputWrapper, 'blog-post-title-color');
    const titleStyle = isMobile ? { fontSize: postTitleFontSizeMobile } : undefined;

    return (
      <div className={containerClassName}>
        <div className={styles.wrapper}>
          {this.renderMetadata()}
          <div className={titleInputWrapperClassName}>
            <InputText
              dir={titleDirection}
              value={titleValue}
              onChange={onTitleChange}
              containerClassName={titleFontClassName}
              className={titleInputClassName}
              style={titleStyle}
              maxLength={limit}
              placeholder={t('post-form.give-this-post-a-title')}
              autoFocus={!postId}
              onDrop={this.handleDrop}
              data-hook="post-form__title-input"
            />
          </div>
          <div className={contentClassName} data-hook="post-form__content">
            {this.renderEditor()}
          </div>
          <PostFormActionBar
            isCtaDisabled={this.isCtaDisabled()}
            fastForm={fastForm}
            onClickSave={this.props.savePostDraft}
            onClickPublish={this.props.publishPost}
            onClickCancel={this.onClickCancel}
          />
        </div>
      </div>
    );
  }
}

PostForm.FOCUSABLE_ID_CATEGORY_TOGGLE = 'post-form__category-toggle';
PostForm.FOCUSABLE_ID_SETTINGS_TOGGLE = 'post-form__settings-toggle';

PostForm.propTypes = {
  t: PropTypes.func,
  postId: PropTypes.string,
  isPublished: PropTypes.bool,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  limit: PropTypes.number,
  owner: PropTypes.object.isRequired,
  hasUnpublishedChanges: PropTypes.bool,
  timeToRead: PropTypes.number,
  openPostSettings: PropTypes.func.isRequired,
  videoHost: PropTypes.string.isRequired,
  mediaHost: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  postTitleFontSizeMobile: PropTypes.number,
  fastForm: PropTypes.object,
  savePostDraft: PropTypes.func.isRequired,
};

PostForm.defaultProps = {
  limit: POST_TITLE_MAXLENGTH,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const post = getPostEditorPost(state) || {};

  return {
    postId: post._id,
    hasUnpublishedChanges: getHasUnpublishedChanges(state),
    timeToRead: get(post, 'draft.timeToRead', 1),
    isPublished: isPublished(post),
    owner: getPostEditorPostOwner(state),
    postTitleFontSizeMobile: getPostTitleFontSizeMobile(state),
    openPostSettings: actions.openPostSettings,
    goToFeed: actions.goToFeed,
  };
};

export default flowRight(
  withPostPublish({ resetOnUnmount: true }),
  withAuth,
  withTranslate,
  withFontClassName,
  withDeviceType,
  withMediaHosts,
  connect(mapRuntimeToProps),
)(PostForm);
